microflow.controller("pageCtrl", [
  "$controller",
  "$scope",
  "$route",
  "$routeParams",
  "$location",
  "$http",
  "microflowFac",
  "authenFac",
  function(
    $controller,
    $scope,
    $route,
    $routeParams,
    $location,
    $http,
    microflowFac,
    authenFac
  ) {
    try {
      angular.extend(this, $controller("microflowCtrl", { $scope: $scope }));
    } catch (err) {
      console.error(err);
    }
  }
]);
